
import { defineComponent, ref } from 'vue';

import { usePlans } from '@/composables/api';
import SearchBox from '@/components/keyword-searching/Index.vue';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import permissionUnits from '@/components/permission-units/index.vue';

const FILTER_OPTIONS: FilterOption[] = [
  {
    type: FilterType.CHECKBOX,
    label: 'Show'
  },
  {
    type: FilterType.CHECKBOX,
    label: 'Best'
  }
];

export default defineComponent({
  components: {
    SearchBox,
    Filter,
    permissionUnits
  },
  setup() {
    const page = ref(1);
    const keyword = ref('');
    // eslint-disable-next-line @typescript-eslint/camelcase
    const is_best = ref();
    const show = ref();
    // eslint-disable-next-line @typescript-eslint/camelcase
    const { data, isLoading, isFetching } = usePlans({ page, keyword, is_best, show });

    const searchKeyword = (_keyword: string) => {
      keyword.value = _keyword;
    };

    const handleFilterChange = (event: FilterEvent) => {
      // mutate ref
      show.value = event[0] ? '1' : '';
      // eslint-disable-next-line @typescript-eslint/camelcase
      is_best.value = event[1] ? '1' : '';
    };

    return {
      page,
      data,
      isLoading,
      isFetching,
      FILTER_OPTIONS,
      searchKeyword,
      handleFilterChange
    };
  }
});
